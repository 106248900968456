import { Divider, HStack, Stack, Text, Image } from '@chakra-ui/react';
import React from 'react';
import useIsMobile from '../../../../hooks/useIsMobile';
import { i_34, i_h1 } from '../../../../style';

const HomePiece1: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <Stack
            backgroundSize="cover"
            w="100%"
            h={{ base: '776px', sm: '1559px' }}
            boxSizing="border-box"
            overflow="hidden"
            pos="relative"
            p="30px"
        >
            <Image
                position="absolute"
                left={{ base: '-200px', sm: '86px', xl: '100px', xlp1: '150px', '2xl': '215px' }}
                top={{ base: '-150px', sm: '-328px' }}
                src={isMobile ? '/assets/home/homepiece1/mobileBall.png' : process.env.PUBLIC_URL + '/assets/home/homepiece1/ball.png'}
                fallbackSrc={process.env.PUBLIC_URL + '/assets/home/homepiece1/ball.png'}
                width={{ base: '804px', sm: '1292px' }}
                height={{ base: 'unset', sm: '1455px' }}
                maxW="unset"
            />
            <Stack
                position="absolute"
                left={{ base: '40px', sm: '150px', xxl: '300px', xlp1: '23%' }}
                top={{ base: '186px', sm: '312px' }}
                spacing="0px"
            >
                <HStack w="fit-content" bg="#2A3535">
                    <Divider orientation="vertical" w="8px" h={{ base: '41px', sm: '83px' }} bg="#61FCFC" opacity="1"></Divider>
                    <Text className={i_h1} px={{ base: '17px', sm: '37px' }} py={{ base: '7px', sm: '7px' }}>
                        Multi-chain
                    </Text>
                </HStack>
                <HStack w="fit-content" bg="#2A3535" ml={{ base: '24px !important', sm: '52px !important' }}>
                    <Text
                        className={i_h1}
                        pl={{ base: '21px', sm: '51px' }}
                        pr={{ base: '16px', sm: '32px' }}
                        py={{ base: '7px', sm: '17px' }}
                    >
                        Liquidity Superloop
                    </Text>
                </HStack>

                <HStack
                    w={{ base: '100%', sm: '960px' }}
                    color={{ base: '#98EBF6', sm: '#91C2C9' }}
                    mt={{ base: '86px !important', sm: '240px !important' }}
                    pl={{ base: '0px', sm: '51px' }}
                    pr="31px"
                    spacing="73px"
                    position="relative"
                >
                    <Stack ml={{ base: '40px !important', sm: 'auto !important' }} spacing="0px">
                        <Text className={i_34} letterSpacing={{ base: '-0.05em', sm: '0em' }}>
                            Map the liquidity to the Multi-Chain
                        </Text>
                        <Text className={i_34} py={{ base: '5px', sm: '17px' }} ml="auto !important">
                            Efficiency&Safety
                        </Text>
                    </Stack>
                </HStack>
            </Stack>
        </Stack>
    );
};

export default HomePiece1;
