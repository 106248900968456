import { useEffect, useMemo } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Home from './views/Home/Home';
import { i18nInitPromise } from './i18n';
import LoadProvider from './providers/LoadProvider';
import AppLayout from './components/layout/AppLayout';
import NotFound404 from './views/NotFound404/NotFound404';
import useIsMobile from './hooks/useIsMobile';
import { MobileHeader } from './views/components/MobileHeader/MobileHeader';
import Header from './views/components/Header/Header';
import './App.css';
import { ComingSoon } from './views/ComingSoon/ComingSoon';

function App() {
    const promiseList = useMemo(() => [i18nInitPromise], []);
    const isMobile = useIsMobile();
    useAutoRedirectToHome();
    const Center = (
        <Switch>
            <Route path="/home">
                <Home />
            </Route>

            <Route path="/deposit">
                <ComingSoon />
            </Route>
            <Route path="/treasury">
                <ComingSoon />
            </Route>

            <Route path="*">
                <NotFound404 />
            </Route>
        </Switch>
    );

    return (
        <LoadProvider promiseList={promiseList}>
            {isMobile ? <AppLayout header={<MobileHeader />} center={Center} /> : <AppLayout header={<Header />} center={Center} />}
        </LoadProvider>
    );
}

export default App;

// Redirect to /home if current pathname is /
function useAutoRedirectToHome() {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') history.push('/home');
    }, [history, location.pathname]);
}

// Returns true if pathname is /home
function useIsHomePage() {
    const location = useLocation();
    return useMemo(() => location.pathname === '/home', [location.pathname]);
}
