import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'Share Liquidity Box',
            img: 'Trade.svg',
            address: '/home',
            inTabBar: true,
            inHeader: true,
            children: [],
        },

        {
            name: 'Deposit/Withdraw',
            img: 'Bridge.svg',
            address: '/deposit',
            inTabBar: true,
            inHeader: true,
            children: [],
        },

        {
            name: 'Treasury',
            img: 'Analytics.svg',
            address: '/treasury',
            inTabBar: true,
            inHeader: true,
            children: [],
        },
    ],
} as { pages: PageConfig[] };
