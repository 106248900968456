import React from 'react';
import { BoxProps } from '@chakra-ui/react';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';

type Props = {
    //
} & BoxProps;

const HomeJumpButton: React.FC<Props> = (props) => {
    const { ...rest } = props;

    return (
        <JumpButton
            w="30px"
            h="30px"
            target="/home"
            type="image"
            imageUrl={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.png'}
            {...rest}
        />
    );
};

export default HomeJumpButton;
