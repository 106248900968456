import { Center, Divider, HStack, Text } from '@chakra-ui/react';
import { i_h1 } from '../../style';

export const ComingSoon: React.FC = () => {
    return (
        <Center w="100%" h="100%" bgImage={'/assets/coming/bg.png'}>
            <HStack w={{ base: '100%', sm: '794px' }} bg="#2A3535">
                <Divider orientation="vertical" w="8px" h="83px" bg="#61FCFC" opacity="1"></Divider>
                <Text className={i_h1} px="37px" py="7px">
                    Coming soon ......
                </Text>
            </HStack>
        </Center>
    );
};
