import { Stack, VStack, Text, Center } from '@chakra-ui/react';
import { i_28_18, i_30, i_32, i_h4 } from '../../../../style';
import './HomePiece3.css';

const HomePiece3: React.FC<unknown> = () => {
    return (
        <VStack w="100%" spacing="58px" mt={{ base: '158px !important', sm: '237px !important' }} pl="54px" pr="45px">
            <VStack className={i_32} color="#62F9F9">
                <Text>Share Liquidity Mechanism</Text>
            </VStack>

            <Stack direction={{ base: 'column', sm: 'row' }} spacing="39px">
                <Center
                    w={{ base: '100%', sm: '480px' }}
                    h={{ base: '184px', sm: '284px' }}
                    bg="rgba(255, 255, 255, 0.09)"
                    border="1px dashed #FFFFFF"
                    borderRadius={{ base: '14px', sm: '40px' }}
                    pl={{ base: '50px', sm: '68px' }}
                    pr="37px"
                >
                    <Stack spacing={{ base: '11px', sm: '22px' }}>
                        <Text className={i_28_18}>What is sl-Token ?</Text>
                        <Text className={i_h4}>
                            sl-Token is a token issued by Shared Liquidity Box with over-collateralized on-chain assets. And it can be
                            redeemed from Ethereum Mainnet with 1:1 ratio.
                        </Text>
                    </Stack>
                </Center>
                <Center
                    w={{ base: '100%', sm: '480px' }}
                    h={{ base: '184px', sm: '284px' }}
                    bg="rgba(255, 255, 255, 0.09)"
                    border="1px dashed #FFFFFF"
                    borderRadius={{ base: '14px', sm: '40px' }}
                    pl={{ base: '50px', sm: '68px' }}
                    pr="37px"
                >
                    <Stack spacing={{ base: '11px', sm: '22px' }}>
                        <Text className={i_28_18}>How to withdraw ?</Text>
                        <Text className={i_h4}>
                            Users can deposit sl-Token into the Smart Boxes contract and will receive the original token on the Ethereum
                            mainnet.
                        </Text>
                    </Stack>
                </Center>
            </Stack>
        </VStack>
    );
};

export default HomePiece3;
