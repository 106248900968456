import { Image, Text, HStack, BoxProps, Stack } from '@chakra-ui/react';
import React from 'react';
import { i_20, i_h4 } from '../../../../style';

const HomePiece8: React.FC<BoxProps> = () => {
    return (
        <Stack w="100%" pt={{ base: '191px', sm: '264px' }} pb="50px" pl={{ base: '30px', sm: '56px' }} pr={{ base: '30px', sm: '96px' }}>
            <Stack
                w="100%"
                direction={{ base: 'column', sm: 'row' }}
                className={i_20}
                color={{ base: '#949494', sm: '#FFFFFF' }}
                justifyContent="space-between"
                alignItems="center"
            >
                <Text className={i_h4}>© 2023 ShareLiquidityBox</Text>
                <HStack spacing={{ base: '9px', sm: '34px' }}>
                    <Image
                        width={{ base: '15px', sm: '36px' }}
                        height={{ base: '15px', sm: '36px' }}
                        src={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.png'}
                        fallbackSrc={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.png'}
                    />
                    <Text className={i_h4}>2023 Share Liquidity Box</Text>
                </HStack>
            </Stack>
        </Stack>
    );
};

export default HomePiece8;
