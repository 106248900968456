import { Text, Image, VStack } from '@chakra-ui/react';
import React from 'react';
import useIsMobile from '../../../../hooks/useIsMobile';
import { i_32 } from '../../../../style';

const HomePiece6: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <VStack w="100%" pt="264px" spacing="38px">
            <VStack className={i_32} color="#62F9F9" px="70px" textAlign="center">
                <Text>For Traders of Shared Liquidity Tokens</Text>
            </VStack>
            <Image
                w={{ base: '100%', sm: '960px' }}
                h={{ base: 'unset', sm: '637px' }}
                px="20px"
                pt={{ base: '24px', sm: '0px' }}
                src={isMobile ? '/assets/home/homePiece6/mobileTrader.svg' : '/assets/home/homePiece6/trader.svg'}
                fallbackSrc={isMobile ? '/assets/home/homePiece6/mobileTrader.svg' : '/assets/home/homePiece6/trader.svg'}
            ></Image>
        </VStack>
    );
};

export default HomePiece6;
