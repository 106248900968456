import { Box, HStack, useColorMode, Image, Stack, Center, Button, MenuList } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import HomeJumpButton from '../../../components/Buttons/HomeJumpButton/HomeJumpButton';
import { useState } from 'react';
import { config } from '../../../config/sidebar/config';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useHistory, useLocation } from 'react-router-dom';
import { i_text_copy } from '../../../style';

export const MobileHeader: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const location = useLocation();
    const history = useHistory();
    const currentPath = location.pathname;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Stack
                w="100%"
                h={isOpen ? '200px' : '67px'}
                px="25px"
                spacing="0px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
            >
                <HStack w="100%" h="67px" minH="67px">
                    <HomeJumpButton />
                    <HStack ml="auto !important" spacing="10px">
                        <Center
                            w="30px"
                            h="30px"
                            cursor="pointer"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        >
                            <Image
                                w="10px"
                                h="10px"
                                src={isOpen ? '/assets/header/mobile/close.svg' : '/assets/header/mobile/arrowDown.svg'}
                                fallbackSrc="/assets/header/mobile/arrowDown.svg"
                            ></Image>
                        </Center>
                    </HStack>
                </HStack>
                <Stack h={isOpen ? '133px' : '0px'} spacing="0px">
                    {isOpen &&
                        config.pages.map((item, index) => {
                            const selected = config.pages[index].address === currentPath;
                            return (
                                <HStack key={index}>
                                    <Button
                                        w="100%"
                                        h="44px"
                                        className={i_text_copy}
                                        fontSize="18px !important"
                                        pr={{ base: '10px', lg: '20px', xl: '44px' }}
                                        color={selected ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)'}
                                        bg="none"
                                        _hover={{ bg: 'none' }}
                                        justifyContent="end"
                                        // _hover={{ bg: 'rgba(255, 255, 255, 0.09)', color: '#01F1F1' }}
                                        onClick={() => {
                                            setSelectedIndex(index);
                                            item.address && history.push(item.address);
                                        }}
                                    >
                                        {item.name}
                                    </Button>
                                </HStack>
                            );
                        })}
                </Stack>
            </Stack>
        </>
    );
};
