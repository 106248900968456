import { useEffect } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import HomePiece1 from './components/HomePiece1/HomePiece1';
import HomePiece2 from './components/HomePiece2/HomePiece2';
import HomePiece3 from './components/HomePiece3/HomePiece3';
import HomePiece6 from './components/HomePiece6/HomePiece6';
import HomePiece7 from './components/HomePiece7/HomePiece7';
import HomePiece4 from './components/HomePiece4/HomePiece4';
import HomePiece5 from './components/HomePiece5/HomePiece5';
import HomePiece8 from './components/HomePiece8/HomePiece8';

const Home: React.FC = () => {
    useEffect(() => {
        document.title = 'Home';
    }, []);

    return (
        <Box
            id="home"
            w="100%"
            overflowY="auto"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none !important',
                },
            }}
            boxSizing="border-box"
            position="relative"
        >
            <Stack
                spacing="0px"
                bgImage={{ base: '/assets/home/mobileBg.png', sm: '/assets/home/bg.png' }}
                bgSize={{ base: 'cover', sm: 'cover' }}
            >
                <HomePiece1 />
                <HomePiece2 />
                <HomePiece3 />
                <HomePiece4 />
                <HomePiece5 />
                <HomePiece6 />
                <HomePiece7 />
                <HomePiece8 />
            </Stack>
        </Box>
    );
};

export default Home;
