import { Text, HStack, Stack, VStack, Divider, Center } from '@chakra-ui/react';
import React from 'react';
import { i_30, i_32, i_h3, i_h4 } from '../../../../style';

const HomePiece4: React.FC = () => {
    return (
        <VStack w="100%" pt={{ base: '159px', sm: '264px' }} pl="52px" pr="43px">
            <VStack className={i_32} color="#62F9F9">
                <Text>Three main participants of Shared Liquidity Box</Text>
            </VStack>
            <Stack pt="50px" spacing="53px">
                <Stack
                    w={{ base: '100%', sm: '960px' }}
                    h="250px"
                    color="#000000"
                    bg="linear-gradient(93.39deg, #01F1F1 0%, #C3BEFF 121.78%)"
                    pl={{ base: '30px', sm: '64px' }}
                    pr={{ base: '30px', sm: '56px' }}
                    py="42px"
                    spacing="20px"
                    borderRadius="20px"
                >
                    <Text className={i_30} fontFamily="Montserrat-SemiBold">
                        Users/Traders
                    </Text>
                    <Divider w="100%" bg="#000000"></Divider>
                    <Stack spacing="0px">
                        <Text className={i_h4}>•Buy or sell sl-Token</Text>
                        <Text className={i_h4}>
                            •Use and take sl-Token&apos;s price and liquidity for reference/index used in other DeFi protocols. •Redeem
                            sl-Token from the Ethereum mainnet with 1:1 ratio.
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    w={{ base: '100%', sm: '960px' }}
                    h="250px"
                    color="#000000"
                    bg="linear-gradient(93.39deg, #01F1F1 0%, #C3BEFF 121.78%)"
                    pl={{ base: '30px', sm: '64px' }}
                    pr={{ base: '30px', sm: '56px' }}
                    py="42px"
                    spacing="20px"
                    borderRadius="20px"
                >
                    <Text className={i_30} fontFamily="Montserrat-SemiBold">
                        Market Makers
                    </Text>
                    <Divider w="100%" bg="#000000"></Divider>
                    <Stack spacing="0px">
                        <Text className={i_h4}>
                            Provide liquidity on the original chain to get sl-Tokens on multi-chains’ Smart Boxes, which is different from
                            EOA wallets and will guarantee sl-Token of shared liquidity only to be interacted with authorised Dexes and
                            pools.
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    w={{ base: '100%', sm: '960px' }}
                    h={{ base: '', sm: '252px' }}
                    color="#000000"
                    bg="linear-gradient(93.39deg, #01F1F1 0%, #C3BEFF 121.78%)"
                    pl={{ base: '30px', sm: '64px' }}
                    pr={{ base: '30px', sm: '56px' }}
                    py={{ base: '20px', sm: '42px' }}
                    spacing="20px"
                    borderRadius="20px"
                >
                    <Text className={i_30} fontFamily="Montserrat-SemiBold">
                        Box Keepers
                    </Text>
                    <Divider w="100%" bg="#000000" mt="9px !important"></Divider>
                    <Stack spacing="0px">
                        <Text className={i_h3}>Responsible for</Text>
                        <HStack spacing={{ base: '8px', sm: '27px' }} pt="16px">
                            <Center
                                w={{ base: '75px', sm: '192px' }}
                                h="47px"
                                bg="rgba(225, 255, 255, 0.49)"
                                border="0.6px dashed #000000"
                                borderRadius="10px"
                            >
                                <Text className={i_h4}>Custodian</Text>
                            </Center>
                            <Center
                                w={{ base: '75px', sm: '192px' }}
                                h="47px"
                                bg="rgba(225, 255, 255, 0.49)"
                                border="0.6px dashed #000000"
                                borderRadius="10px"
                            >
                                <Text className={i_h4}>Monitoring</Text>
                            </Center>
                            <Center
                                w={{ base: '75px', sm: '192px' }}
                                h="47px"
                                bg="rgba(225, 255, 255, 0.49)"
                                border="0.6px dashed #000000"
                                borderRadius="10px"
                            >
                                <Text className={i_h4}>Clearance</Text>
                            </Center>
                        </HStack>
                    </Stack>
                </Stack>
            </Stack>
        </VStack>
    );
};

export default HomePiece4;
