import { Flex, useDisclosure, Box, Image, Text, BoxProps, useBreakpointValue, useColorMode, HStack, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import useInterval from 'ahooks/lib/useInterval';

import { useHistory } from 'react-router-dom';
import Identicon from '../../../components/Identicon';
import WalletModal from '../../../components/Modal/WalletModal/WalletModal';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import ConnectButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/ConnectButton/ConnectButton';
import { useConnectModal } from '../../../providers/ConnectProvider';
import { RootDispatch, RootState } from '../../../state/store';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { getColorThemeSelector } from '../../../utils/funcs';
import './Header.css';
import { useSelector } from 'react-redux';
import { links } from '../../../config/links';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { formatNumber } from '../../../utils/tokenMath';
import LanguageSelect from '../../../components/Sidebar/LanguageSelect/LanguageSelect';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { DropDownMenu } from '../../../components/DropDownMenu/DropDownMenu';
import { useHover } from '../../../hooks/useHover';
import { useIsFixedHeader } from '../../../hooks/useLayoutType';
import BuyiZiButton from '../../../components/BuyiZiButton/BuyiZiButton';
import { i_text_copy } from '../../../style';
import ColorModeSelect from '../../../components/ColorModeSelect/ColorModeSelect';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';

const Header: React.FC<BoxProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onOpenModal: onConnect } = useConnectModal();
    const { chainId, chainName, web3, account } = useWeb3WithDefault();
    const history = useHistory();
    const isPC = useBreakpointValue({ base: false, xl: true });
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [showHeader, setShowHeader] = useState(true);
    const { token } = useSelector((state: RootState) => state);
    const { account: accountModel } = useSelector((state: RootState) => state);

    const isFixedHeader = useIsFixedHeader();

    const disConnect = () => {
        onOpen();
    };

    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const { pageLayout } = useSelector((state: RootState) => state);
    const [headerRef, isHovering] = useHover<any>();
    // const isXl =

    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, 5000);

    return (
        <>
            <Flex
                className={pageLayout.inAnimation ? 'showHeader' : ''}
                alignItems="center"
                flexShrink={0}
                flexGrow={0}
                h={pageLayout.inAnimation ? '0px' : { base: '60px', xl: '60px' }}
                bg={'#1F1F1F'}
                position={'sticky'}
                top="0px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                zIndex="1"
                {...props}
            >
                {showHeader && (
                    <Flex
                        w="100%"
                        h="100%"
                        m="0 auto"
                        alignItems={{ base: 'start', xl: 'center' }}
                        flexDirection={{ base: 'column', xl: 'row' }}
                    >
                        <HStack w={{ base: '100%', xl: 'unset' }} pr="20px">
                            <HStack w={{ base: 'unset', sm: '150px' }} justifyContent="center">
                                <Image
                                    src={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.png'}
                                    fallbackSrc="/assets/home/iziLogo/logo.png"
                                    width="38px"
                                    height={{ base: '38px', xl: '38px' }}
                                    cursor="pointer"
                                    onClick={() => {
                                        history.push('/home');
                                    }}
                                />
                            </HStack>
                            <DropDownMenu
                                buttonProps={{
                                    fontSize: '13px',
                                    px: '0px',
                                    ml: '0px !important',
                                }}
                                menuListProps={{
                                    bg: colorTheme('#FFFFFF', '#292343'),
                                    color: colorTheme('#A7A1AC !important', '#655986 !important'),
                                }}
                                h="100%"
                                alignItems={{ base: 'start', xl: 'center' }}
                            ></DropDownMenu>
                        </HStack>
                    </Flex>
                )}
            </Flex>
        </>
    );
};

export default Header;
