import { Image, Text, HStack, BoxProps, VStack, Stack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { baseURL } from '../../../../config/chains';
import { i_32, i_h4, i_text_copy } from '../../../../style';

const HomePiece7: React.FC<BoxProps> = () => {
    const tabs = [
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/MainSite.svg',
            title: 'iZUMi Finance',
            content: 'A multi-chain DeFi protocol providing one-stop Liquidity as a Service (LaaS)',
            link: 'https://izumi.finance',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Bella.png',
            title: 'Bella Protocol',
            content: 'A suite of DeFi products to bring your familiar mobile banking into crypto with just one-click.',
            link: 'https://bella.fi/',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Near.png',
            title: 'Near Protocol',
            content: 'A sharded, proof-of-stake, layer-one blockchain that is simple to use, secure and scalable',
            link: 'https://near.org/',
        },
        {
            leftIcon: baseURL + 'bnbChain.png',
            title: 'BNB Chain',
            content: 'Discover opBNB, a Layer 2 scaling solution designed to overcome the limitations of Layer 1 networks',
            link: 'https://bnbchain.org/',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Sui.png',
            title: 'Sui',
            content: 'Layer 1 blockchain designed to make digital asset ownership fast, private, secure, and accessible to everyone.',
            link: 'https://sui.io/',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Cetus.png',
            title: 'Cetus',
            content: 'Pioneer DEX and Concentrated Liquidity Protocol built on #Sui & #Aptos Live on Sui & Aptos Mainnet',
            link: 'https://www.cetus.zone/',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Sushi.png',
            title: 'Sushi.com',
            content: 'A community-built open-source ecosystem of all the DeFi tools you need',
            link: 'https://www.sushi.com/',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Ref.png',
            title: 'Ref Finance',
            content:
                'A community-led, multi-purpose DeFi platform built on the NEAR Protocol. The gateway of Near DeFi - First AMM and DEX',
            link: 'https://www.ref.finance/',
        },
        {
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece7/Biswap.png',
            title: 'Biswap',
            content: 'A Progressive DEX on the BNBChain with low fees from 0.1% profitable features & Multi-type Referral program',
            link: 'https://biswap.org/',
        },
    ];

    return (
        <VStack w="100%" pt="264px" spacing="38px" px="32px">
            <Stack w={{ base: '100%', sm: '960px' }} className={i_32} color="##FFFFFF">
                <Text>Backers</Text>
            </Stack>
            <SimpleGrid maxW="960px" columns={{ base: 1, lg: 3, xxl: 4 }} spacing="18px" my="25px">
                {tabs.map((item, index) => {
                    return (
                        <Stack
                            key={index}
                            w={{ base: '100%', sm: '100%%' }}
                            h={{ base: 'unset', sm: '240px' }}
                            flexShrink={0}
                            bg="#464C53"
                            color="#FFFFFF"
                            pl="21px"
                            pt="26px"
                            pb="30px"
                            pr="20px"
                            borderRadius="20px"
                            cursor="pointer"
                            onClick={() => {
                                window.open(item.link);
                            }}
                        >
                            <HStack>
                                <Image maxW="34px" maxH="34px" src={item.leftIcon}></Image>
                                <Text className={i_h4} isTruncated={true}>
                                    {item.title}
                                </Text>
                            </HStack>

                            <Text className={i_text_copy} noOfLines={5} pt="13px">
                                {item.content}
                            </Text>
                            <Image
                                w="13px"
                                h="11px"
                                mt={{ base: '10px !important', sm: 'auto !important' }}
                                ml="auto !important"
                                src="/assets/home/homePiece7/arrowRight.svg"
                            ></Image>
                        </Stack>
                    );
                })}
            </SimpleGrid>
        </VStack>
    );
};

export default HomePiece7;
