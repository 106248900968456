import { Text, Image, VStack, HStack, Circle } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import useIsMobile from '../../../../hooks/useIsMobile';
import { i_32 } from '../../../../style';

const HomePiece5: React.FC = () => {
    const isMobile = useIsMobile();
    const SUBMENU_CHEVRON_CLICK_MOVE_PX = 352;
    const scrollLayerRef = useRef<HTMLDivElement>(null);
    const [switchRight, setSwitchRight] = useState(true);

    return (
        <VStack w="100%" pt="264px" spacing="0px">
            <VStack w="100%" className={i_32} px="70px" color="#62F9F9" textAlign="center">
                <Text>For Market Makers of Shared Liquidity Box</Text>
            </VStack>
            {isMobile ? (
                <HStack
                    w="100%"
                    overflowX="auto"
                    alignItems="start"
                    position="relative"
                    ref={scrollLayerRef}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                        scrollBehavior: 'smooth',
                    }}
                >
                    <Circle position="absolute" boxSize="30px" bg="#F1FEFF" cursor="pointer" right="10px" top="50%">
                        <Image
                            src={switchRight ? '/assets/home/homePiece5/scrollRight.svg' : '/assets/home/homePiece5/scrollLeft.svg'}
                            onClick={() => {
                                setSwitchRight(!switchRight);
                                if (!scrollLayerRef.current) return;
                                if (switchRight) {
                                    scrollLayerRef.current.scrollLeft += SUBMENU_CHEVRON_CLICK_MOVE_PX;
                                } else {
                                    scrollLayerRef.current.scrollLeft -= SUBMENU_CHEVRON_CLICK_MOVE_PX;
                                }
                            }}
                        ></Image>
                    </Circle>
                    <Image
                        w={{ base: '352px', sm: '960px' }}
                        h={{ base: 'unset', sm: '637px' }}
                        px="20px"
                        pt={{ base: '24px', sm: '0px' }}
                        src={'/assets/home/homePiece5/mobileMakers1.svg'}
                        fallbackSrc="/assets/home/homePiece5/mobileMakers1.svg"
                    ></Image>
                    <Image
                        w={{ base: '352px', sm: '960px' }}
                        h={{ base: 'unset', sm: '637px' }}
                        px="20px"
                        pt={{ base: '24px', sm: '0px' }}
                        src={'/assets/home/homePiece5/mobileMakers2.svg'}
                        fallbackSrc="/assets/home/homePiece5/mobileMakers2.svg"
                    ></Image>
                </HStack>
            ) : (
                <Image
                    w={{ base: '100%', sm: '960px' }}
                    h={{ base: 'unset', sm: '637px' }}
                    px="20px"
                    pt={{ base: '24px', sm: '0px' }}
                    src={isMobile ? '/assets/home/homePiece5/mobileMakers.png' : '/assets/home/homePiece5/Makers.svg'}
                    fallbackSrc="/assets/home/homePiece5/trader.svg"
                ></Image>
            )}
        </VStack>
    );
};

export default HomePiece5;
