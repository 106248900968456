import React, { useState, useCallback, useContext, useRef } from 'react';
import {
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import CustomButton from '../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { getCurrentConnector, useWeb3WithDefault } from '../hooks/useWeb3WithDefault';
import { ConnectorNames, DEFAULTCHAINID } from '../utils/connectors';
import { switchNetwork } from '../utils/metamaskWallet';
import { i_text_copy, i_text_copy_bold } from '../style';

interface Toast {
    showWithNetwork: (title: string, text: string) => void;
}

const ToastContext = React.createContext<Toast>(undefined!);

export const useToastWithNetwork = () => {
    return useContext(ToastContext);
};

const ToastWithNetworkProvider: React.FC<{}> = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const okButton = useRef();
    const { account } = useWeb3WithDefault();

    const [toastData, setToastData] = useState<{
        title: string;
        text: string;
    }>();

    const showWithNetwork = useCallback(
        (title: string, text: string) => {
            setToastData({
                title: title,
                text: text,
            });
            onOpen();
        },
        [onOpen]
    );

    return (
        <ToastContext.Provider
            value={{
                showWithNetwork: showWithNetwork,
            }}
        >
            <AlertDialog
                // @ts-ignore
                leastDestructiveRef={okButton}
                onClose={onClose}
                isOpen={isOpen}
                isCentered={true}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>{toastData?.title || '...'}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {toastData?.text || '...'}
                        <CustomButton
                            onClick={() => {
                                switchNetwork(DEFAULTCHAINID).then((res) => {
                                    onClose();
                                });
                            }}
                            text="Switch To ZkSync Era"
                            variant="none"
                            w="100%"
                            h="40px"
                            className={i_text_copy_bold}
                            color="#ffffff"
                            bg="#d7443f"
                            _hover={{ bg: '#e36864' }}
                            borderRadius="5px"
                            my="10px"
                        />
                    </AlertDialogBody>
                    {/* <AlertDialogFooter>
                        <CustomButton onClick={onClose} text="Ok" variant="none" radiusType="lg" />
                    </AlertDialogFooter> */}
                </AlertDialogContent>
            </AlertDialog>
            {children}
        </ToastContext.Provider>
    );
};

export default ToastWithNetworkProvider;
